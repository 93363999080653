import React   from 'react'
import Dayjs   from 'dayjs'

import DatePicker from '../date_picker'
import Search     from './search'
import Search2    from '../company_search'

// 買収ニーズ
import Needs    from '../needs'

// Ajax
import Request from 'superagent'
require('superagent-rails-csrf')(Request);

/**
 *  @version 2018/06/10
 */
export default class Reports extends React.Component {

  /**
   *  コンストラクタ
   *  @version 2018/06/10
   */
  constructor(props) {

    super(props);

    let visits = props.report.visits || [];

    visits.forEach((visit, i) => {

      // 買収ニーズ
      visit.needs = visit.needs || [];
      (props.report.needs || []).forEach((need, j) => {
        if (need.company_id == visit.company_id) visit.needs.push(need);
      });
    });

    this.state = {
      report: props.report,
      visits: visits,
    };
  }

  /**
   *  公開日時を適用するcallback
   *  @version 2018/06/10
   */
  setReportAt = datetime => {

    let report = this.state.report;
    report.reported_at = datetime.datetime.format('YYYY-MM-DD');

    this.setState({
      report: report,
    });
  }

  // 面談先を追加する
  addVisit = () => {

    let visits = (this.state.visits || []).slice();
    visits.push({
      visit_type: 'visit',
      id: null,
      company_id: null,
      company_type: 'seller',
      visit_method: 'normal',
      sales_type: 'existing',
      bound_type: 'outbound',
      status: 'yet',
      phase: 'yet_phase',
      apointment_route: 'via_dm',
    });

    this.setState({ visits: visits });
  }

  // 面談先を削除する
  removeVisit = e => {

    let visits = (this.state.visits || []).slice();

    visits.splice(e.target.dataset.index, 1);

    visits.forEach((visit, index) => {
      this.refs[`company_${index}`].get_company(visit.company_id);
    });

    this.setState({ visits: visits });
  }

  // 会社の変更
  changeCompany = company => {

    // リスト情報の取得
    Request.get(`/users/groups/get_groups?company_id=${company.id}`)
      .set('X-Requested-With', 'XMLHttpRequest')
      .setCsrfToken()
      .end((error, response) => {

        if (response.body.status == 'success' && response.body.groups) {

          const visits = (this.state.visits || []).slice();
          
          visits.forEach((visit, index) => {

            // 該当企業
            if (this.refs[`company_${index}`].index() == index && (!visit.company_id || visit.company_id == company.id)) {

              visit.company_id = company.id;
              visit.company_type = (company.company_type == 'seller' || company.company_type == 'buyer' || company.company_type == 'accountant_alliance') ? company.company_type : 'seller';
              visit.bound_type = 'outbound';
              visit.company_name = company.name;
              visit.groups = response.body.groups;
            }
          });

          this.setState({ visits: visits });
        }
      });
  }

  // アポ経路
  changeGroup = e => {

    let visits = (this.state.visits || []).slice();
    visits[e.target.dataset.index].group_id = e.target.value;

    this.setState({ visits: visits });
  }

  // 取引種別の変更
  changeCompanyType = e => {

    let visits = (this.state.visits || []).slice();
    visits[e.target.dataset.index].company_type = e.target.value;

    this.setState({ visits: visits });
  }

  changeVisitMethod = e => {

    let visits = (this.state.visits || []).slice();
    visits[e.target.dataset.index].visit_method = e.target.value;

    this.setState({ visits: visits });
  }

  changeSalesType = e => {
    
    let visits = (this.state.visits || []).slice();
    visits[e.target.dataset.index].sales_type = e.target.value;

    this.setState({ visits: visits });
  }

  changeBoundType = e => {
    
    let visits = (this.state.visits || []).slice();
    visits[e.target.dataset.index].bound_type = e.target.value;

    this.setState({ visits: visits });
  }

  changeStatus = e => {
    
    let visits = (this.state.visits || []).slice();
    visits[e.target.dataset.index].status = e.target.value;

    this.setState({ visits: visits });
  }

  changePartnerPhase = e => {
    
    let visits = (this.state.visits || []).slice();
    visits[e.target.dataset.index].partner_phase = e.target.value;

    this.setState({ visits: visits });
  }

  changeRank = e => {
    
    let visits = (this.state.visits || []).slice();
    visits[e.target.dataset.index].rank = e.target.value;

    this.setState({ visits: visits });
  }

  changeApointmentRoute = e => {
    
    let visits = (this.state.visits || []).slice();
    visits[e.target.dataset.index].apointment_route = e.target.value;

    this.setState({ visits: visits });
  }

  changePhase = e => {

    let visits = (this.state.visits || []).slice();
    visits[e.target.dataset.index].phase = e.target.value;

    visits[e.target.dataset.index].needs = [];

    if (e.target.value == 'hearing_needs') {
      visits[e.target.dataset.index].needs.push({
        user_id: this.props.user_id,
        prefecture: '',
        budget: null,
        sales: null,
        profit: null,
        follow: false,
        overview: '',
        _destroy: false,
      });
    }
    this.setState({ visits: visits });
  }

  changeVisitBody = e => {
    
    let visits = (this.state.visits || []).slice();
    visits[e.target.dataset.index].body = e.target.value;

    this.setState({ visits: visits });
  }

  changeFollow = e => {

    let visits = (this.state.visits || []).slice();
    visits[e.target.dataset.index].follow = e.target.checked;

    this.setState({ visits: visits });
  }

  onSubmit = e => {

    e.stopPropagation();

    if (e.target.dataset.status == 'complete' && !window.confirm('日報を投稿しますか?')) {
      return false;
    }

    const request = this.props.report.id ? Request.put(`/users/reports/${this.props.report.id}`) : Request.post('/users/reports');

    let fields = {
      'report[reported_at]': Dayjs(this.state.report.reported_at || this.props.datetime).format('YYYY-MM-DD'),
      'report[body]': this.refs.body.value,
      'report[status]': e.target.dataset.status,
    };

    let error = false;
    let client_count = 0;
    let needs_count = 0;

    if (this.state.visits.length > 0) {

      this.state.visits.forEach((visit, index) => {

        // 面談先企業
        const company = this.refs[`company_${index}`].company();
        let client_email_empty = false;

        if (!company) {
          error = true;
          return;
        }

        if (!this.refs[`company_${index}`]) {
          error = true;
          return;
        }

        // 面談先企業担当者
        this.refs[`company_${index}`].clients().forEach((client, i) => {

          if (client.id) fields[`company_clients[${client_count}][id]`] = client.id;
          if (client.company_id) fields[`company_clients[${client_count}][company_id]`] = client.company_id;
          if (client.name) fields[`company_clients[${client_count}][name]`] = client.name;
          if (client.email) fields[`company_clients[${client_count}][email]`] = client.email;
          if (client.tel) fields[`company_clients[${client_count}][tel]`] = client.tel;
          if (client.tel2) fields[`company_clients[${client_count}][tel2]`] = client.tel2;
          if (client.post) fields[`company_clients[${client_count}][post]`] = client.post;
          if (client.fax) fields[`company_clients[${client_count}][fax]`] = client.fax;
          if (client.note) fields[`company_clients[${client_count}][note]`] = client.note;
          if (client.main) fields[`company_clients[${client_count}][main]`] = client.main;
          if (client.has_card) fields[`company_clients[${client_count}][has_card]`] = client.has_card;
          if (client.is_president) fields[`company_clients[${client_count}][is_president]`] = client.is_president;
          if (client._destroy) fields[`company_clients[${client_count}][_destroy]`] = client._destroy;

          // 面談、web面談の場合は直通担当者は必須
          if (!client.id && !client._destroy && (!client.name || !client.email || client.name == '' || client.email == '')) {
            client_email_empty = true;
          }

          client_count++;
        });

        // 面談した企業
        let suggest_company = null;
        if (this.refs[`suggest_company_${index}`]) {
          suggest_company = this.refs[`suggest_company_${index}`].company();
          if (!suggest_company) {
            error = true;
            return;
          }
        }

        // 紹介された企業
        let referral_company = null;
        if (this.refs[`referral_company_${index}`]) {
          referral_company = this.refs[`referral_company_${index}`].company();
          if (!referral_company) {
            error = true;
            return;
          }
        }

        fields[`report[visits_attributes][${index}][company_id]`] = company.id;
        fields[`report[visits_attributes][${index}][visit_type]`] = visit.visit_type;

        if (this.refs[`company_type_${index}`]) {
          fields[`report[visits_attributes][${index}][company_type]`] = this.refs[`company_type_${index}`].value;
        }
        // 面談方法
        if (this.refs[`visit_method_${index}`]) {
          if (!this.refs[`visit_method_${index}`].value || this.refs[`visit_method_${index}`].value == '') {
            error = true;
            return;
          } else{
            fields[`report[visits_attributes][${index}][visit_method]`] = this.refs[`visit_method_${index}`].value;

            if (client_email_empty && ['normal', 'web'].includes(this.refs[`visit_method_${index}`].value)) {
              error = true;
            }
          }
        }
        if (this.refs[`sales_type_${index}`]) {
          fields[`report[visits_attributes][${index}][sales_type]`] = this.refs[`sales_type_${index}`].value;
        }
        if (this.refs[`bound_type_${index}`]) {
          fields[`report[visits_attributes][${index}][bound_type]`] = this.refs[`bound_type_${index}`].value;
        }
        if (this.refs[`apointment_route_${index}`]) {
          fields[`report[visits_attributes][${index}][apointment_route]`] = this.refs[`apointment_route_${index}`].value;
        }
        if (this.refs[`group_id_${index}`]) {
          fields[`report[visits_attributes][${index}][group_id]`] = this.refs[`group_id_${index}`].value;
        }
        if (this.refs[`status_${index}`]) {
          fields[`report[visits_attributes][${index}][status]`] = this.refs[`status_${index}`].value;
        }
        if (this.refs[`phase_${index}`]) {
          fields[`report[visits_attributes][${index}][phase]`] = this.refs[`phase_${index}`].value;
        }
        if (this.refs[`partner_phase_${index}`]) {
          fields[`report[visits_attributes][${index}][partner_phase]`] = this.refs[`partner_phase_${index}`].value;
        }
        if (this.refs[`rank_${index}`]) {
          fields[`report[visits_attributes][${index}][rank]`] = this.refs[`rank_${index}`].value;
        }
        if (this.refs[`visit_body_${index}`]) {
          fields[`report[visits_attributes][${index}][body]`] = this.refs[`visit_body_${index}`].value;
        }
        if (this.refs[`follow_${index}`]) {
          fields[`report[visits_attributes][${index}][follow]`] = this.refs[`follow_${index}`].checked;
        }
        if (suggest_company) {
          fields[`report[visits_attributes][${index}][suggest_company_id]`] = suggest_company.id;
        }
        if (referral_company) {
          fields[`report[visits_attributes][${index}][referral_company_id]`] = referral_company.id;
        }
        
        if (this.refs[`needs_${index}`]) {

          const needs = this.refs[`needs_${index}`].needs();

          (needs || []).forEach((need, ni) => {
            
            if (need.id && need.id != '') fields[`report[needs_attributes][${needs_count}][id]`] = need.id;
            if (company.id) fields[`report[needs_attributes][${needs_count}][company_id]`] = company.id;
            if (need.user_id) fields[`report[needs_attributes][${needs_count}][user_id]`] = need.user_id;
            if (need.budget) fields[`report[needs_attributes][${needs_count}][budget]`] = need.budget;
            if (need.sales) fields[`report[needs_attributes][${needs_count}][sales]`] = need.sales;
            if (need.profit) fields[`report[needs_attributes][${needs_count}][profit]`] = need.profit;
            if (!need.prefecture || need.prefecture == '') need.prefecture = '指定なし';
            fields[`report[needs_attributes][${needs_count}][prefecture]`] = need.prefecture;
            if (need.category_id) fields[`report[needs_attributes][${needs_count}][category_id]`] = need.category_id;
            if (need.overview) fields[`report[needs_attributes][${needs_count}][overview]`] = need.overview;
            if (need._destroy) fields[`report[needs_attributes][${needs_count}][_destroy]`] = need._destroy;

            needs_count++;
          });
        }
      });
    }

    if (error) {
      window.alertable({ type: 'warning', message: '未入力の項目があります' });
      return false;
    }

    request
      .attach('report[document]', this.refs.ducument ? this.refs.ducument.files[0] : null)
      .field(fields)
      .set('X-Requested-With', 'XMLHttpRequest')
      .setCsrfToken()
      .end((error, response) => {

        window.closeLoading();

        if (response.body.status == 'success') {
          
          window.alertable({ type: 'success', message: response.body.report.status == 'complete' ? '日報を投稿しました' : '日報を一時保存しました', close_callback: () => {
            if (this.props.is_modal) {
              parent.location.href = '/users/reports/';
            } else {
              window.location.href = '/users/reports/';
            }
          }});

        } else {
          window.alertable({ type: 'error', message: response.body.message });
        }
      });
    
    window.startLoading();
  }

  /**
   *  表示処理
   *  @version 2018/06/10
   */
  render() {

    return (
      <div className='new_report'>

        <div className='c-form-label'>
          <label htmlFor='report_reported_at'>対象日</label>
        </div>
        <DatePicker type='text' apply={this.setReportAt} default_datetime={this.props.datetime} />

        { this.state.visits.map((visit, index) => {

          const key = 'visit-'+index;
          return (
            <div className='u-mt-20 c-around' {...{key}}>
              <div className='u-fw-bold u-mb-15'>
              面談{index+1}
                <span className='c-btnMain-negative u-ml-30 c-btnSmall' data-index={index} onClick={(e) => { this.removeVisit(e) }}>ー 面談{index+1}を削除する</span>
              </div>
              { visit.company_id ?
                null
                :
                <div className='c-attention u-fs-small u-mt-30'>
                  <div className='c-attention__title'>
                    ⚠️
                    <span className='u-fc-red u-fw-bold'>注意</span>
                  </div>
                  ※ TOP面談を実施した場合は、
                  <span className='u-fc-red u-fw-bold'>譲受</span>
                  企業を入力して面談内容を記載してください。
                </div>
              }
              <div className='u-mt-10'>
                <Search label='会社名' ref={`company_${index}`} index={index} company_id={visit.company_id} changeCompany={this.changeCompany}/>
              </div>

              <div className='c-form-label u-mt-30'>
                <label>取引種別</label>
              </div>

              <div className='c-form-selectWrap'>
                <select className='c-form-select' value={visit.company_type} ref={`company_type_${index}`} data-index={index} onChange={this.changeCompanyType}>
                  <option value='seller'>譲渡</option>
                  <option value='buyer'>譲受</option>
                  <option value='accountant_alliance'>その他（パートナー提携先等）</option>
                  <option value='unset'>未設定</option>
                </select>
              </div>

              <div className='u-mt-30 c-attention u-fs-small'>
                <div className='c-attention__title'>
                  ⚠️
                  <span className='u-fc-red u-fw-bold'>注意</span>
                </div>
                <div>
                  初回の対面/WEB面談は「<span className='u-fc-red u-fw-bold'>新規</span>」を選択してください。
                  <br />
                  事前に<span className='u-fw-bold'>TEL面談を行なっていた場合</span>や、<span className='u-fw-bold'>他者が既に面談を行っていた場合</span>でも「<span className='u-fc-red u-fw-bold'>新規</span>」を選択してください。
                  <br />
                  集計に影響するため、<span className='u-fc-red u-fw-bold'>上記以外の場合</span>は必ず「<span className='u-fc-red u-fw-bold'>既存</span>」を選択してください。
                </div>
              </div>

              <div>
                <label className='c-form-label'>
                  面談方法
                  <span className='u-fs-small'>
                    （新規面談数のKPIには
                    <span className='u-fc-red u-fw-bold'>対面・WEB面談のみカウント</span>
                    されます。
                    <span className='u-fc-red u-fw-bold'>TEL面談は含まれません。</span>
                    ）
                  </span>
                </label>
                <div className='c-form-selectWrap'>
                  <select className='c-form-select' value={visit.visit_method} ref={`visit_method_${index}`} data-index={index} onChange={this.changeVisitMethod} required={true}>
                    <option value='normal'>対面面談</option>
                    <option value='web'>Web面談</option>
                    <option value='tel'>TEL (テレアポ取得時 or 意思決定者と具体的な会話をした場合に本項目を選択)</option>
                    <option value='mail'>メール</option>
                    <option value='other'>その他（備忘等）</option>
                  </select>
                </div>
              </div>

              { ['seller', 'accountant_alliance'].includes(visit.company_type) ?

                <div>
                  <div className='u-display-flex'>
                    <div style={{flex: 1}}>
                      <div className='c-form-label'>
                        <label>新規/既存</label>
                      </div>
                      <div className='c-form-selectWrap'>
                        <select className='c-form-select' value={visit.sales_type || 'existing'} ref={`sales_type_${index}`} data-index={index} onChange={this.changeSalesType}>
                          <option value='new_customer'>新規</option>
                          <option value='existing'>既存</option>
                        </select>
                      </div>
                    </div>
                    <div className='u-ml-15' style={{flex: 1}}>
                      <div className='c-form-label'>
                        <label>営業種別</label>
                      </div>
                      <div className='c-form-selectWrap'>
                        <select className='c-form-select' value={visit.bound_type || 'outbound'} ref={`bound_type_${index}`} data-index={index} onChange={this.changeBoundType}>
                          <option value='outbound'>アウトバウンド</option>
                          <option value='inbound'>インバウンド</option>
                          <option value='otherbound'>その他</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  { visit.sales_type == 'new_customer' && visit.bound_type != 'otherbound' ?
                    <div className='c-attention u-fs-small u-mt-30'>
                      <div className='c-attention__title'>
                        ⚠️
                        <span className='u-fc-red u-fw-bold'>注意</span>
                      </div>
                      <span className='u-fc-red u-fw-bold'>営業種別がアウト/インバウンド以外</span>の場合は「<span className='u-fc-red u-fw-bold'>その他</span>」を選択し、<span className='u-fc-red u-fw-bold'>アポ経路の詳細を面談内容に記載</span>してください。
                      <br />
                      (○○の紹介など)
                    </div>
                    : null
                  }

                  { visit.sales_type == 'new_customer' && visit.bound_type == 'otherbound' ?
                    <div className='c-attention u-fs-small u-mt-30'>
                      <div className='c-attention__title'>
                        ⚠️
                        <span className='u-fc-red u-fw-bold'>注意</span>
                      </div>
                      営業種別がその他の場合は<span className='u-fc-red u-fw-bold'>アポ経路の詳細を面談内容に記載</span>してください。
                      <br />
                      (○○の紹介など)
                    </div>
                    : null
                  }

                  { visit.sales_type == 'new_customer' && visit.bound_type == 'outbound' && (visit.visit_method == 'normal' || visit.visit_method == 'web') ?
                    <div>
                      <div className='c-form-label'>
                        <label>アポ経路</label>
                      </div>
                      <div className='c-form-selectWrap'>
                        <select className='c-form-select' value={visit.apointment_route || 'via_dm'} ref={`apointment_route_${index}`} data-index={index} onChange={this.changeApointmentRoute}>
                          <option value='via_mail'>手紙の返信によるアポ</option>
                          <option value='via_dm'>DMの返信によるアポ</option>
                          <option value='via_tel'>自らのテレアポ</option>
                          <option value='via_outsource'>外注テレアポ</option>
                          <option value='via_other'>リストを使用せずに取得したアポ</option>
                        </select>
                      </div>

                      { visit.apointment_route != 'via_other' ?
                        <div>
                          <div className='c-form-label'>
                            <label>アポを取得したソーシングリスト</label>
                          </div>
                          <div className='c-form-selectWrap'>
                            <select className='c-form-select' value={visit.group_id} ref={`group_id_${index}`} data-index={index} onChange={this.changeGroup}>
                              { (visit.groups || []).map(group => {
                                const group_key = 'group-'+index;
                                return (<option key={group_key} value={group.id}>{group.name}</option>);
                              })}
                            </select>
                          </div>
                        </div>
                        :
                        null
                      }
                    </div>
                    :
                    null
                  }
                </div>
                :
                null
              }

              { visit.company_type == 'buyer' ?
                <div>
                  <div className='c-form-label'>
                    <label>フェーズ</label>
                  </div>
                  <div className='c-form-selectWrap'>
                    <select className='c-form-select' value={visit.phase || 'yet_phase'} ref={`phase_${index}`} data-index={index} onChange={this.changePhase}>
                      <option value='yet_phase'>-</option>
                      <option value='hearing_needs'>買収ニーズヒアリング</option>
                      <option value='first_visit'>初回提案</option>
                      <option value='before_top_interview'>TOP面談前</option>
                      <option value='top_interview'>TOP面談</option>
                      <option value='deal_phase'>ディール</option>
                    </select>
                  </div>
                </div>
                :
                null
              }
              
              { visit.company_type == 'accountant_alliance' ?
                <div className='u-display-flex'>
                  <div style={{flex: 1}}>
                    <div className='c-form-label'>
                      <label>面談内容</label>
                    </div>
                    <div className='c-form-selectWrap'>
                      <select className='c-form-select' value={visit.partner_phase || 'partner_yet'} ref={`partner_phase_${index}`} data-index={index} onChange={this.changePartnerPhase}>
                      <option value='partner_yet'>-</option>
                        <option value='referral'>紹介面談</option>
                        <option value='partner_follow'>フォロー面談</option>
                        <option value='partner_close'>今後フォロー不要</option>
                      </select>
                    </div>
                  </div>
                  <div className='u-ml-15' style={{flex: 1}}>
                    <div className='c-form-label'>
                      <label>ランク</label>
                    </div>
                    <div className='c-form-selectWrap'>
                      <select className='c-form-select' value={visit.rank || 'd'} ref={`rank_${index}`} data-index={index} onChange={this.changeRank}>
                        <option value='d'>D</option>
                        <option value='c'>C</option>
                        <option value='b'>B</option>
                        <option value='a'>A</option>
                      </select>
                    </div>
                  </div>
                </div>
                :
                null
              }

              { visit.company_type == 'buyer' && visit.phase == 'hearing_needs' ?
                <div className='u-mt-15'>
                  <Needs company_id={visit.company_id} needs={visit.needs} categories={this.props.categories} ref={`needs_${index}`} user_id={this.props.user_id} />
                </div>
                :
                null
              }

              { visit.company_type == 'buyer' && visit.phase != 'hearing_needs' && visit.phase != 'yet_phase' && visit.phase != 'first_visit' ?
                <Search2 label='提案した会社名' ref={`suggest_company_${index}`} company_id={visit.suggest_company_id} /> : null
              }

              { visit.company_type == 'accountant_alliance' && visit.partner_phase == 'referral' ?
                <div>
                  <div className='c-attention u-fs-small u-mt-30'>
                    <div className='c-attention__title'>
                      ⚠️
                      <span className='u-fc-red u-fw-bold'>注意</span>
                    </div>
                    <span className='u-fc-red'><span className='u-fw-bold'>譲受企業</span>の紹介を受けた場合は法人部に直接ご連絡ください。</span>
                  </div>
                  <Search2 label='紹介を受けた譲渡企業' ref={`referral_company_${index}`} company_id={visit.referral_company_id} />
                </div>
                : null
              }

              <div className='u-mt-30'>
                <label className='c-form-checkboxLabel'>
                  <input className='c-form-checkbox' type='checkbox' checked={visit.follow || false} value='1' ref={`follow_${index}`} data-index={index} onChange={this.changeFollow} />
                  <i className='c-form-checkboxIcon'></i>
                  <span className='u-va-middle u-ml-5'>本件の担当者でない帯同者（上席等）の場合はチェックを入れてください</span>
                </label>
              </div>

              <div className='c-form-label u-mt-15'>
                <label>面談内容をフリーワードで記載してください</label>
              </div>
              <textarea rows='6' className='c-form-textarea' value={visit.body} ref={`visit_body_${index}`} data-index={index} placeholder='面談内容を記述してください' autoComplete='off' spellCheck='false' onChange={this.changeVisitBody} />
            </div>
          );
        })}

        <div className='c-btnMain-standard u-mt-15' onClick={this.addVisit}>＋ 面談内容を追加する</div>
        <hr />
        <div className='c-form-label u-mt-30'>
          <label>添付資料</label>
        </div>
        <div className='u-mt-5'>
          <input type='file' ref='ducument' />
        </div>
        <div className='c-form-label u-mt-15'>
          <label>日報内容</label>
        </div>
        <textarea rows='15' className='c-form-textarea' defaultValue={this.props.report.body} ref='body' placeholder='日報の内容を記述してください。' autoComplete='off' spellCheck='false'></textarea>
        
        <div className='c-attention u-fs-small u-mt-30'>
          <div className='c-attention__title'>
            ⚠️
            <span className='u-fc-red u-fw-bold'>注意</span>
          </div>
          初回の投稿のみチャットワークに通知されます。<br />編集中に一時的に保存したい場合は「一時保存」を行なってください。一時保存時はチャットワークに通知されません。<br /><span className='u-fc-red u-fw-bold'>※一次保存中の日報は集計などにカウントされません。</span>
        </div>

        <div className='u-ta-center u-mt-30'>
          <div className='c-btnSub-standard' data-status='yet' onClick={this.onSubmit}>一時保存</div>
          <div className='c-btnMain-standard u-ml-30' data-status='complete' onClick={this.onSubmit}>投稿する</div>
        </div>
      </div>
    );
  }
}
