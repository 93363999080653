import React      from 'react'
import Modal from '../utilities/modal'
import Style from './style.sass'

// Ajax
import Request from 'superagent'
require('superagent-rails-csrf')(Request);

/**
 *  記事エディター
 *  @version 2018/06/10
 */
export default class CompanySearch extends React.Component {

  constructor(props) {

    super(props)

    this.state = {
      companies: [],
      company_id: props.company_id,
      name: '',
      company_name: props.company_name || '',
      target_index: null,
    }
  }

  componentDidMount() {

    if (this.props.is_modal) {
      
      // グローバルからの呼び出し
      window.openCompanySearch = index => {
        this.open(index);
      };
    }
    
    if (this.props.company_id) {

      this.get_company(this.props.company_id);
    }
  }

  open = index => {
    this.setState({target_index: index, companies: [], company_id: null, name: '', company_name: ''}, () => {
      this.refs.search_modal.open();
    });
  }

  get_company = company_id => {

    if (!company_id) return false;

    // 会社情報の取得
    Request.get(`/users/get_company/${company_id}/`)
      .set('X-Requested-With', 'XMLHttpRequest')
      .setCsrfToken()
      .end((error, response) => {

        if (response.body.status == 'success' && response.body.company) {

          this.setState({
            company_id: response.body.company.id,
            company_name: response.body.company.name,
            company: response.body.company,
          }, () => {

            const {callback, is_modal} = this.props;
            if (callback) {
              if (typeof callback == 'string' || callback instanceof String) {
                eval(`${callback}(${this.state.company_id}, '${this.state.company_name}', ${this.state.target_index})`);
              } else {
                callback(this.state.company_id, this.state.company_name, this.state.target_index);
              }
              if (is_modal) this.refs.search_modal.close();
            }
          });

        } else {
          window.alertable({ type: 'error', message: '[初期表示]エラーにより会社情報が取得できませんでした。' });
        }
      });
  }

  /**
   *  submit処理
   *  @version 2018/06/10
   */
  _onSubmit = (e) => {

    e.stopPropagation();

    this.get_company(e.target.dataset.id);
    this.refs.result_modal.close();
  }

  /**
   *  会社IDの取得
   *  @version 2018/06/10
   */
  company = () => {

    return this.state.company;
  }

  onChangeName = (e) => {
    
    e.stopPropagation();

    this.setState({ company_name: e.target.value, company_id: null });
  }

  /**
   *  モーダルを表示する
   *  @version 2018/06/10
   */
  check = () => {

    if (this.state.company_name == '') {
      window.alertable({ type: 'warning', message: '会社名を入力してください' });
      return false;
    }

    window.startLoading();

    let url = `/users/get_companies/?name=${encodeURIComponent(this.state.company_name)}`;

    if (this.refs.name_search_type && this.refs.name_search_type.value != '') url += `&name_search_type=${this.refs.name_search_type.value}`;
    if (this.refs.prefecture_id && this.refs.prefecture_id.value != '') url += `&prefecture_id=${this.refs.prefecture_id.value}`;

    // 会社情報の取得
    Request.get(url)
      .set('X-Requested-With', 'XMLHttpRequest')
      .setCsrfToken()
      .end((error, response) => {

        if (response.body.status == 'success' && response.body.companies) {

          this.setState({companies: response.body.companies || []}, () => {

            window.closeLoading();
            
            if (response.body.companies.length > 0) {
              this.refs.result_modal.open(); 
            } else {
              window.alertable({ type: 'warning', message: '会社情報が見つかりませんでした。' });
            }
          });
        } else {
          window.closeLoading();
          this.setState({companies: []}, () => {
            window.alertable({ type: 'error', message: '[会社情報取得]エラーにより会社情報が取得できませんでした。' });
          });
        }
      });
  }

  /**
   *  アラートに表示するコンテンツ
   *  @version 2018/06/10
   */
  result_content() {

    return (
      <div className={Style.Search}>
        <div className={Style.Search__inner}>
          { this.state.companies.map((company, index) => {
            const key = 'company-'+index;
            return (
              <div {...{key}} className={Style.Search__item} data-name={company.name} data-id={company.id} onClick={this._onSubmit}>
                <div className='u-fs-large u-fw-bold'>{company.name}</div>
                {company.company_label ? <div dangerouslySetInnerHTML={{ __html: company.company_label}}></div> : null}
                <div><span className='u-fc-thinBlack'>ID:</span> {company.id}<span className='u-fc-thinBlack u-ml-30'>法人番号:</span> {company.number || '-'}</div>
                <div><span className='u-fc-thinBlack'>都道府県:</span> {company.prefecture}</div>
                <div><span className='u-fc-thinBlack'>住所:</span> {company.address}</div>
                <div><span className='u-fc-thinBlack'>代表者名:</span> {company.president_name}</div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  /**
   *  表示処理
   *  @version 2018/06/10
   */
  search_content() {

    return (
      <div>
        <div className='c-form-label u-mt-10'>
          <label htmlFor='company_name'>{this.props.label}</label>
          { (!this.props.required || this.props.required == false) ? null : <span className='c-form__required u-ml-10'>必須</span> }
        </div>
        <div className='u-display-flex'>
          <input type='hidden' name={this.props.name} value={this.state.company_id || ''} />
          <div style={{flex: 1, position: 'relative'}}>
            <input type='text' value={this.state.company_name} onChange={this.onChangeName} className='c-form-text' placeholder='会社名、もしくはIDを入力してください' required={(!this.props.required || this.props.required == false) ? false : true}/>
            
            <div className={Style.Search__type}>
              <select className={Style.Search__select} defaultValue={''} ref='name_search_type'>
                <option value=''>あいまい</option>
                <option value='prefix'>前方一致</option>
                <option value='absolute'>完全一致</option>
              </select>
            </div>

            <div className={Style.Search__prefecture}>
              <select className={Style.Search__select} defaultValue={''} ref='prefecture_id'>
                <option value=''>全国</option>
                <option value='1'>北海道</option>
                <option value='2'>青森県</option>
                <option value='3'>岩手県</option>
                <option value='4'>宮城県</option>
                <option value='5'>秋田県</option>
                <option value='6'>山形県</option>
                <option value='7'>福島県</option>
                <option value='8'>茨城県</option>
                <option value='9'>栃木県</option>
                <option value='10'>群馬県</option>
                <option value='11'>埼玉県</option>
                <option value='12'>千葉県</option>
                <option value='13'>東京都</option>
                <option value='14'>神奈川県</option>
                <option value='15'>新潟県</option>
                <option value='16'>富山県</option>
                <option value='17'>石川県</option>
                <option value='18'>福井県</option>
                <option value='19'>山梨県</option>
                <option value='20'>長野県</option>
                <option value='21'>岐阜県</option>
                <option value='22'>静岡県</option>
                <option value='23'>愛知県</option>
                <option value='24'>三重県</option>
                <option value='25'>滋賀県</option>
                <option value='26'>京都府</option>
                <option value='27'>大阪府</option>
                <option value='28'>兵庫県</option>
                <option value='29'>奈良県</option>
                <option value='30'>和歌山県</option>
                <option value='31'>鳥取県</option>
                <option value='32'>島根県</option>
                <option value='33'>岡山県</option>
                <option value='34'>広島県</option>
                <option value='35'>山口県</option>
                <option value='36'>徳島県</option>
                <option value='37'>香川県</option>
                <option value='38'>愛媛県</option>
                <option value='39'>高知県</option>
                <option value='40'>福岡県</option>
                <option value='41'>佐賀県</option>
                <option value='42'>長崎県</option>
                <option value='43'>熊本県</option>
                <option value='44'>大分県</option>
                <option value='45'>宮崎県</option>
                <option value='46'>鹿児島県</option>
                <option value='47'>沖縄県</option>
                <option value='48'>海外</option>
              </select>
            </div>

            { this.state.company_id ?
              <div className={Style.Search__checked}></div>
              : null
            }
          </div>
          <div onClick={this.check} className='c-btnMain-standard u-ml-15' style={{width: '30%', lineHeight: '30px'}}>チェック</div>
          <Modal ref='result_modal' yield={this.result_content()}/>
        </div>

        { this.state.company && this.state.company.company_label ?
          <div dangerouslySetInnerHTML={{ __html: this.state.company.company_label}}></div>
          : null
        }

        { !this.state.company_id ?
          <div className='u-fs-small u-fc-thinBlack u-fw-normal'>※ 会社名もしくはIDを入力しチェックを行ってください</div> : null
        }
      </div>
    );
  }

  /**
   *  表示処理
   *  @version 2018/06/10
   */
  render() {

    if (this.props.is_modal) {
      return <Modal ref='search_modal' width='800px' yield={this.search_content()}/>;
    } else {
      return this.search_content();
    }
  }
}