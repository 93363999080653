import React      from 'react'
import Style   from './style.sass'
import Dayjs   from 'dayjs'
import Icon  from 'users/icon'

/**
 *  記事エディター
 *  @version 2018/06/10
 */
export default class Clients extends React.Component {

  constructor(props) {

    super(props)

    this.state = { clients: (this.props.clients.length > 0) ? this.props.clients : [
      {
        company_id: this.props.company_id,
        name: '',
        email: '',
        tel: '',
        post: '',
        tel2: '',
        fax: '',
        note: '',
        main: false,
        has_card: false,
        is_president: false,
        _destroy: false,
        editing: true,
        show: true,
      }
    ],
    hidden_detail: this.props.hidden_detail || false,
    }
  }

  updateClients = () => {
    this.setState({ clients: (this.props.clients.length > 0) ? this.props.clients : [{
      company_id: this.props.company_id,
      name: '',
      email: '',
      tel: '',
      post: '',
      tel2: '',
      fax: '',
      note: '',
      main: false,
      has_card: false,
      is_president: false,
      _destroy: false,
      editing: true,
      show: true,
    }] });
  }

  /**
   *  担当者の取得
   *  @version 2018/06/10
   */
  clients = () => {

    let clients = (this.state.clients || []).slice();
    clients = clients.map( client => {
      return client;
    });

    return this.state.clients;
  }

  // 担当者の追加
  addClient = e => {

    let clients = (this.state.clients || []).slice();

    clients.push({
      company_id: this.props.company_id,
      name: '',
      email: '',
      tel: '',
      post: '',
      tel2: '',
      fax: '',
      note: '',
      main: false,
      has_card: false,
      is_president: false,
      _destroy: false,
      editing: true,
      show: true,
    });

    this.setState({ clients: clients });
  }

  // 担当者の更新
  changeClientValue = e => {

    const index = e.target.dataset.index;

    let clients = (this.state.clients || []).slice();

    clients[index] = {
      id: clients[index].id,
      company_id: this.refs[`company_id_${index}`].value,
      name: this.refs[`name_${index}`].value,
      email: this.refs[`email_${index}`].value,
      tel: this.refs[`tel_${index}`].value,
      tel2: this.refs[`tel2_${index}`].value,
      fax: this.refs[`fax_${index}`].value,
      note: this.refs[`note_${index}`].value,
      post: this.refs[`post_${index}`].value,
      main: this.refs[`main_${index}`].checked,
      has_card: this.refs[`has_card_${index}`].checked,
      is_president: this.refs[`is_president_${index}`].checked,
      _destroy: clients[index]._destroy,
      editing: clients[index].editing,
      show: true,
    };

    this.setState({ clients: clients });
  }

  // 編集可否
  changeEditable = e => {

    let clients = (this.state.clients || []).slice();

    let client = clients[e.target.dataset.index];

    if (client.editing && client.name == '' && client.email == '' && client.tel == '' && client.post == '' ) client._destroy = true;

    client.editing = !client.editing;

    this.setState({ clients: clients });
  }

  // 担当者の削除
  removeClient = e => {

    let clients = (this.state.clients || []).slice();

    let client = clients[e.target.dataset.index];

    if ((client.name == '' && client.email == '' && client.tel == '' && client.post == '' ) || window.confirm('担当者を削除しますか?')) {

      client._destroy = true;

      this.setState({ clients: clients });
    }
  }

  // 担当者の閲覧
  showMore = e => {

    this.setState({ hidden_detail: false });
  }

  /**
   *  表示処理
   *  @version 2018/06/10
   */
  render() {

    return (
      <div>
        { this.state.clients.map((client, index) => {
          const key = `client_${index}`;
          let border_color = null;
          if (client.source == 'center') border_color = '#0D847B';
          if (client.source == 'strike') border_color = '#00AFEB';

          if (this.state.hidden_detail && index > 4 && !client.editing) return null;

          return (
            client.editing ?
              <div style={{display: client._destroy ? 'none' : 'block'}} className={`${Style.Clients} c-attention ${index > 0 ? 'u-mt-15' : ''}`} {...{key}}>
                <div className='u-fw-bold'>
                  担当者{index+1}
                  { /* <div className={Style.Clients__edit} data-index={index} onClick={this.changeEditable}>閉じる</div> */ }
                  { /*
                    this.state.clients.length > 1 ?
                    <div data-index={index} onClick={e => { this.removeClient(e) }} className={Style.Clients__close}>
                      削除
                    </div>
                    : null
                    */
                  }
                </div>
                <div>
                  <input type='hidden' value={client.id || ''} ref={`company_id_${index}`} name={'company[clients_attributes][' + index + '][id]'} />
                  <input type='hidden' value={this.props.company_id} ref={`company_id_${index}`} name={'company[clients_attributes][' + index + '][company_id]'} />
                  <input type='hidden' value={client._destroy || false} name={'company[clients_attributes][' + index + '][_destroy]'} />
    
                  <div className='u-display-flex'>
                    <div style={{flex: '1'}}>
                      <div className='c-form-label'>
                        <label className='u-fs-small'>担当者名</label>
                      </div>
                      <div>
                        <input type='text' value={client.name} className='c-form-text' placeholder='担当者名を入力してください' ref={`name_${index}`} data-index={index} onChange={this.changeClientValue} name={'company[clients_attributes][' + index + '][name]'}/>
                      </div>
                    </div>
                    <div className='u-ml-15' style={{flex: '1'}}>
                      <div className='c-form-label'>
                        <label className='u-fs-small'>役職</label>
                      </div>
                      <div>
                        <input type='text' value={client.post} className='c-form-text' placeholder='役職を入力してください' ref={`post_${index}`} data-index={index} onChange={this.changeClientValue} name={'company[clients_attributes][' + index + '][post]'}/>
                      </div>
                    </div>
                    <div className='u-ml-15' style={{flex: '1'}}>
                      <div className='c-form-label'>
                        <label className='u-fs-small'>メールアドレス</label>
                      </div>
                      <div>
                        <input type='text' value={client.email} className='c-form-text' placeholder='メールアドレスを入力してください' ref={`email_${index}`} data-index={index} onChange={this.changeClientValue} name={'company[clients_attributes][' + index + '][email]'}/>
                      </div>
                    </div>
                  </div>
                  <div className='u-display-flex'>
                    <div style={{flex: '1'}}>
                      <div className='c-form-label'>
                        <label className='u-fs-small'>電話番号1</label>
                      </div>
                      <div>
                        <input type='text' value={client.tel} className='c-form-text' placeholder='電話番号を入力してください' ref={`tel_${index}`} data-index={index} onChange={this.changeClientValue} name={'company[clients_attributes][' + index + '][tel]'}/>
                      </div>
                    </div>
                    <div className='u-ml-15' style={{flex: '1'}}>
                      <div className='c-form-label'>
                        <label className='u-fs-small'>電話番号2</label>
                      </div>
                      <div>
                        <input type='text' value={client.tel2} className='c-form-text' placeholder='電話番号2を入力してください' ref={`tel2_${index}`} data-index={index} onChange={this.changeClientValue} name={'company[clients_attributes][' + index + '][tel2]'}/>
                      </div>
                    </div>
                    <div className='u-ml-15' style={{flex: '1'}}>
                      <div className='c-form-label'>
                        <label className='u-fs-small'>FAX番号</label>
                      </div>
                      <div>
                        <input type='text' value={client.fax} className='c-form-text' placeholder='FAX番号を入力してください' ref={`fax_${index}`} data-index={index} onChange={this.changeClientValue} name={'company[clients_attributes][' + index + '][fax]'}/>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className='c-form-label'>
                      <label className='u-fs-small'>備考</label>
                    </div>
                    <div>
                      <input type='text' value={client.note} className='c-form-text' placeholder='備考を入力してください' ref={`note_${index}`} data-index={index} onChange={this.changeClientValue} name={'company[clients_attributes][' + index + '][note]'}/>
                    </div>
                  </div>
                  <div className='u-mt-15 u-display-flex'>
                    <div style={{flex: '1'}}>
                      <label className='c-form-checkboxLabel'>
                        <span className='c-form-label'>メイン窓口</span>
                        <input className='c-form-checkbox' type='checkbox' checked={client.main} value='1' ref={`main_${index}`} data-index={index} onChange={this.changeClientValue}/>
                        <i className='c-form-checkboxIcon'></i>
                      </label>
                      <input type='hidden' value={client.main} name={'company[clients_attributes][' + index + '][main]'}/>
                    </div>
                    <div className='u-ml-15' style={{flex: '1'}}>
                      <label className='c-form-checkboxLabel'>
                        <span className='c-form-label'>代表者</span>
                        <input className='c-form-checkbox' type='checkbox' checked={client.is_president} value='1' ref={`is_president_${index}`} data-index={index} onChange={this.changeClientValue}/>
                        <i className='c-form-checkboxIcon'></i>
                      </label>
                      <input type='hidden' value={client.is_president} name={'company[clients_attributes][' + index + '][is_president]'}/>
                    </div>
                    <div className='u-ml-15' style={{flex: '1'}}>
                      <label className='c-form-checkboxLabel'>
                        <span className='c-form-label'>名刺あり</span>
                        <input className='c-form-checkbox' type='checkbox' checked={client.has_card} value='1' ref={`has_card_${index}`} data-index={index} onChange={this.changeClientValue}/>
                        <i className='c-form-checkboxIcon'></i>
                      </label>
                      <input type='hidden' value={client.has_card} name={'company[clients_attributes][' + index + '][has_card]'}/>
                    </div>
                  </div>
                </div>
            </div>
            :
            <div style={{display: client._destroy ? 'none' : 'block'}} {...{key}}>
              <div className='u-fs-small u-fw-bold u-mt-15' style={{position: 'relative'}}>
                担当者{index+1}
                <div className={Style.Clients__edit} data-index={index} onClick={this.changeEditable}>編集</div>
              </div>
              <div className='js-simplebar c-table u-mt-5' style={{borderColor: border_color }}>
                <table className='c-table__content' style={{borderColor: border_color }}>
                  <tbody>
                    <tr style={{borderColor: border_color }}>
                      <td width='100px' style={{borderColor: border_color }}>担当者名</td>
                      <td style={{borderColor: border_color }}>
                        {client.name}
                        { client.is_president ? <span className='c-label blue u-ml-10'>代表者</span> : null }
                        { client.main ? <span className='c-label orange u-ml-10'>メイン窓口</span> : null }
                        { client.has_card ? <span className='c-label main u-ml-10'>名刺あり</span> : null }
                      </td>
                    </tr>
                    <tr>
                      <td width='100px' style={{borderColor: border_color }}>役職</td>
                      <td style={{borderColor: border_color }}>{client.post}</td>
                    </tr>
                    <tr>
                      <td width='100px' style={{borderColor: border_color }}>メールアドレス</td>
                      <td style={{borderColor: border_color }}><a className='u-td-underline' href={`mailto:${client.email}`}>{client.email}</a></td>
                    </tr>
                    <tr>
                      <td width='100px' style={{borderColor: border_color }}>電話番号1</td>
                      <td style={{borderColor: border_color }}>
                        { client.tel && client.tel != '' ?
                          <span className='u-mr-5 u-cursor-pointer' onClick={() => (window.parent || window).openTwilio(this.props.company_id, this.props.company_name, client.name, client.tel, null, this.props.company_company_id)}><Icon name='phone' size='s' color='main' /></span>
                          : null
                        }
                        {client.tel}
                      </td>
                    </tr>
                    <tr>
                      <td width='100px' style={{borderColor: border_color }}>電話番号2</td>
                      <td style={{borderColor: border_color }}>
                        { client.tel2 && client.tel2 != '' ?
                          <span className='u-mr-5 u-cursor-pointer' onClick={() => (window.parent || window).openTwilio(this.props.company_id, this.props.company_name, client.name, client.tel2, null, this.props.company_company_id)}><Icon name='phone' size='s' color='main' /></span>
                          : null
                        }
                        {client.tel2}
                      </td>
                    </tr>
                    <tr>
                      <td width='100px' style={{borderColor: border_color }}>FAX番号</td>
                      <td style={{borderColor: border_color }}>{client.fax}</td>
                    </tr>
                    <tr>
                      <td width='100px' style={{borderColor: border_color }}>備考</td>
                      <td style={{borderColor: border_color }}>{client.note}</td>
                    </tr>
                    <tr>
                      <td width='100px' style={{borderColor: border_color }}>登録日時</td>
                      <td style={{borderColor: border_color }}>{Dayjs(client.created_at).format('YYYY年MM月DD日')}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          );
        })}

        <div className='c-btnMain-standard u-mt-15 c-btnSmall' onClick={this.addClient}>＋ 担当者を追加する</div>

        {
          this.state.hidden_detail && this.state.clients.length > 4 ?
          <div className='u-ta-center u-cursor-pointer u-fs-small u-td-underline u-mt-5' onClick={this.showMore}>もっと見る ({this.state.clients.length - 5}件)</div>
          : null
        }
      </div>
    );
  }
}
