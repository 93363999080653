import React   from 'react'
import Style from './style.sass'

import ApprovalValues from './values'
import ApprovalFlows from './flows'

// Ajax
import Request from 'superagent'
require('superagent-rails-csrf')(Request);

import { STATUSES, APPROVAL_TYPES, USE_TYPES } from './properties'

/**
 *  @version 2018/06/10
 */
export default class Approval extends React.Component {

  /**
   *  コンストラクタ
   *  @version 2018/06/10
   */
  constructor(props) {

    super(props);
  }

  // 保存
  onSubmit = e => {
    
    e.stopPropagation();

    let error = false;
    const request = this.props.approval.id ? Request.put(`/users/approvals/${this.props.approval.id}`) : Request.post('/users/approvals');

    if (this.refs.name.value == '') {
      error = true;
    }

    let fields = {
      'approval[name]': this.refs.name.value,
      'approval[approval_type]': this.refs.approval_type.value,
      'approval[use_type]': this.refs.use_type.value,
      'approval[status]': this.refs.status.value,
      'approval[is_important_matters]': this.refs.is_important_matters.checked,
      'approval[is_hr]': this.refs.is_hr.checked,
      'approval[anti_check]': this.refs.anti_check.checked,
      'approval[postable]': this.refs.postable.checked,
      'approval[cloudsignable]': this.refs.cloudsignable.checked,
      'approval[notice]': this.refs.notice.value,
      'approval[note]': this.refs.note.value,
    };

    // 新規登録時のみ変更可能
    if (!this.props.approval.id) {

      // 稟議項目
      this.refs.values.getValues().map((value, index) => {

        if (value.name != '') {

          fields[`approval[values_attributes][${index}][value_type]`] = value.value_type;
          fields[`approval[values_attributes][${index}][sort]`] = value.sort;
          fields[`approval[values_attributes][${index}][name]`] = value.name;
          fields[`approval[values_attributes][${index}][required]`] = value.required;
        }
      });
    }

    // 稟議フロー
    this.refs.flows.getFlows().map((flow, index) => {

      if (['division_master', 'division_anyone'].includes(flow.approval_flow_type) && (!flow.division_id || flow.division_id == '')) {
        error = true;
      }

      if (flow.id) fields[`approval[flows_attributes][${index}][id]`] = flow.id;
      if (flow._destroy) fields[`approval[flows_attributes][${index}][_destroy]`] = flow._destroy;
      if (flow.division_id && flow.division_id != '') fields[`approval[flows_attributes][${index}][division_id]`] = flow.division_id;
      if (flow.target_user_id && flow.target_user_id != '') fields[`approval[flows_attributes][${index}][target_user_id]`] = flow.target_user_id;
      fields[`approval[flows_attributes][${index}][approval_flow_type]`] = flow.approval_flow_type;
      fields[`approval[flows_attributes][${index}][sort]`] = flow.sort;
    });

    if (error) {
      window.alertable({ type: 'error', message: '未入力の項目があります' });
      return false;
    }

    request
      .field(fields)
      .set('X-Requested-With', 'XMLHttpRequest')
      .setCsrfToken()
      .end((error, response) => {

        if (response.body.status == 'success') {

          window.alertable({ type: 'success', message: response.body.message });
          window.location.href = `/users/approvals/`;
        } else {

          window.alertable({ type: 'error', message: response.body.message });
        }
      });
  }

  /**
   *  表示処理
   *  @version 2018/06/10
   */
  render() {

    return (
      <div className={Style.Approval}>

        <div className='c-form-label u-mt-15'>
          <label>稟議名</label>
        </div>
        <div>
          <input type='text' defaultValue={this.props.approval.name} className='c-form-text' placeholder='稟議名を入力してください' ref='name' name='approval[name]'/>
        </div>

        <div className='c-form-label u-mt-15'>
          <label>実行種別</label>
        </div>
        <div className='c-form-selectWrap'>
          <select className='c-form-select' defaultValue={this.props.approval.use_type} ref='use_type'>
            { USE_TYPES.map((type, index) => {
              const key = 'use-'+index;
              return (
                <option {...{key}} value={type.value}>{type.name}</option>
              );
            })}
          </select>
        </div>

        <div className='c-form-label u-mt-15'>
          <label>対: 法人/個人/指定なし</label>
        </div>
        <div className='c-form-selectWrap'>
          <select className='c-form-select' defaultValue={this.props.approval.approval_type} ref='approval_type' disabled={!!this.props.approval.id}>
            { APPROVAL_TYPES.map((type, index) => {
              const key = 'type-'+index;
              return (
                <option {...{key}} value={type.value}>{type.name}</option>
              );
            })}
          </select>
        </div>

        <div className='c-form-label u-mt-15'>
          <label>注意事項</label>
        </div>
        <div>
          <textarea ref='notice' name='approval[notice]' defaultValue={this.props.approval.notice} placeholder='例: 件名は社名 + 契約名 としてください' rows='2' className='c-form-textarea u-mt-5' autoComplete='off' />
        </div>

        <div className='u-mt-15'>
          <label className='c-form-checkboxLabel'>
            <span className='c-form-label'>反社チェック必須</span>
            <input className='c-form-checkbox u-ml-10' type='checkbox' defaultChecked={this.props.approval.anti_check} ref='anti_check' />
            <i className='c-form-checkboxIcon'></i>
          </label>
          <span className='u-ml-10 u-fs-small'>※ チェックを入れた場合は反社チェックが済んでいない場合は申請できなくなります。</span>
        </div>

        <div className='u-mt-15'>
          <label className='c-form-checkboxLabel'>
            <span className='c-form-label'>郵送を受け付ける</span>
            <input className='c-form-checkbox u-ml-10' type='checkbox' defaultChecked={this.props.approval.postable} ref='postable' />
            <i className='c-form-checkboxIcon'></i>
          </label>
          <span className='u-ml-10 u-fs-small'>※ チェックを入れた場合は稟議承認後に書類の郵送までを行う必要があります。</span>
        </div>

        <div className='u-mt-15'>
          <label className='c-form-checkboxLabel'>
            <span className='c-form-label'>電子契約での契約を受け付ける</span>
            <input className='c-form-checkbox u-ml-10' type='checkbox' defaultChecked={this.props.approval.cloudsignable} ref='cloudsignable' />
            <i className='c-form-checkboxIcon'></i>
          </label>
          <span className='u-ml-10 u-fs-small'>※ チェックを入れた場合は電子契約で締結を許可します</span>
        </div>

        <div className='u-mt-15'>
          <label className='c-form-checkboxLabel'>
            <span className='c-form-label'>重要事項説明書類の添付を受け付ける</span>
            <input className='c-form-checkbox u-ml-10' type='checkbox' defaultChecked={this.props.approval.is_important_matters} ref='is_important_matters' />
            <i className='c-form-checkboxIcon'></i>
          </label>
          <span className='u-ml-10 u-fs-small'>※ チェックを入れた場合は重要事項説明書類を添付できるようになります</span>
        </div>

        <div className='u-mt-15'>
          <label className='c-form-checkboxLabel'>
            <span className='c-form-label'>人事のみ閲覧可能</span>
            <input className='c-form-checkbox u-ml-10' type='checkbox' defaultChecked={this.props.approval.is_hr} ref='is_hr' />
            <i className='c-form-checkboxIcon'></i>
          </label>
          <span className='u-ml-10 u-fs-small'>※ チェックを入れた場合は人事のみ閲覧が可能になります</span>
        </div>

        <div className='c-warning u-mt-30 u-fs-small'>
          稟議は一度作成すると<span className='u-fw-bold u-fc-red'>項目の変更を行うことはできません</span>。変更を行いたい場合は、無効にしてから新しく稟議を作成してください。
        </div>
        <ApprovalValues ref='values' values={this.props.values} disabled={!!this.props.approval.id} />
        <ApprovalFlows flows={this.props.flows} users={this.props.users} divisions={this.props.divisions} default_division_id={this.props.divisions[0].id} ref='flows'/>

        <div className='u-mt-15'>
          <div className='c-form-label'>
            <label className='u-fs-small'>備考の初期値</label>
          </div>
          <textarea ref='note' defaultValue={this.props.approval.note} placeholder='備考欄の初期値として記述する文言を記入してください' rows='5' className='c-form-textarea u-mt-5' autoComplete='off' />
        </div>

        <div className='c-form-label u-mt-15'>
          <label>有効/無効</label>
          <span className='u-ml-10 u-fs-small'>※ 無効にした稟議は申請することができなくなります。削除の代わりにご利用ください。</span>
        </div>
        <div className='c-form-selectWrap'>
          <select className='c-form-select' defaultValue={this.props.approval.status} ref='status'>
            { STATUSES.map((status, index) => {
              const key = 'status-'+index;
              return (
                <option {...{key}} value={status.value}>{status.name}</option>
              );
            })}
          </select>
        </div>

        <div className='u-ta-center u-mt-30'>
          <div className='c-btnMain-standard' onClick={this.onSubmit}>保存する</div>
        </div>
      </div>
    );
  }
}
