//##############################################################################
// Initializer Application
//##############################################################################
import 'core-js/stable';

const Rails = require('@rails/ujs')

import 'scripts/utilities';
import 'scripts/initializer';
import 'scripts/pagy';
import SimpleBar from 'simplebar';
import 'simplebar/dist/simplebar.css';
//import {Sortable} from '@shopify/draggable';
import RailsUjsJack from 'scripts/rails_ujs_jack';

RailsUjsJack.jack(Rails);
Rails.start();

Initializer.drawerRight();
Initializer.navigation();

// スクロールバーカスタマイズ
document.querySelectorAll('.js-simplebar').forEach(r => {
  new SimpleBar(r, { autoHide: false });
});

// react
import WebpackerReact from 'webpacker-react'

// flashメッセージ
import DataModal from 'users/data_modal'

// サジェスト選択
import Suggests from 'users/suggests'

// サジェスト選択
import SuggestSelect from 'users/suggest_select'

// かわいいキャラ
import Kawaii from 'users/kawaii'

// CSVアップロード
import CsvUploader from 'users/csv_uploader'

// ファイルアップロード
import FileUploader from 'users/file_uploader'

// グループ登録
import GroupRegister from 'users/group_register'

// グループ登録
import LonglistRegister from 'users/longlist_register'

// ローディング
import Loading from 'users/loading'

// 会社のURL登録
import CompanyWebsite from 'users/company_website'

// カレンダー
import DatePicker from 'users/date_picker'

// タグ紐付け
import CompaniesTags from 'users/companies_tags'

// 動画タグタグ紐付け
import MoviesHashtags from 'users/movies_hashtags'

// 日報
import Reports from 'users/reports'

// 買収ニーズ
import Needs from 'users/needs'

// 担当者
import Clients from 'users/clients'

// 会社検索
import CompanySearch from 'users/company_search'

// 反社チェック(個人)検索
import ClientSearch from 'users/client_search'

// 総研接点
import CompanyUsers from 'users/company_users'

// 稟議
import Approval from 'users/approval'

// かんたん稟議申請
import EasyNda from 'users/easy_nda'

// 企業詳細表示
import CompanyDetail from 'users/company_detail'

// モーダルページ
import ModalPage from 'users/modal_page'

// モーダルページ
import ModalSinglePage from 'users/modal_single_page'

// 電話詳細
import TelReport from 'users/tel_report'

// Twilio
import Twilio from 'users/twilio'

// アポ登録
import Appointment from 'users/appointment'

// 検索クエリの保存
import UserQueries from 'users/user_queries'

// 検索クエリリスト
import UserQueriesList from 'users/user_queries_list'

// 検索クエリリスト
import GroupQueriesList from 'users/group_queries_list'

// 利用規約への同意
import Regulations from 'users/regulations'

// メール問い合わせ
import GroupCompanyMailer from 'users/group_company_mailer'

// メール問い合わせ
import CheckMail from 'users/check_mail'

// タグ検索
import Tags from 'users/tags'

// エリア検索
import Areas from 'users/areas'

// 反社チェック
import AntiChecks from 'users/anti_checks'

// ナレッジ
import Knowledge from 'users/knowledge'
import KnowledgeTree from 'users/knowledge/knowledge_tree'
import KnowledgeEditor from 'users/knowledge_editor'

// 社内規定
import Rule from 'users/rule'
import RuleTree from 'users/rule/rule_tree'
import RuleEditor from 'users/rule_editor'

// 名刺チェック
import BusinessCard from 'users/business_card'

// ファイルツリー
import FileTree from 'users/file_tree'

// 動画再生
import Movie from 'users/movie'

// 棒グラフ
import BarCharts from 'users/bar_charts'

// カラーピッカー
import ColorPicker from 'users/color_picker'

// 汎用フォーム
import { MultipleForm } from 'users/multiple_forms'

// マルチプルセレクト
import { MultipleSelect } from 'users/multiple_select'

// マトリックス
import { Matrix } from 'users/matrix'

import ClosingPackageForm from '../users/closing_package';

// チャット
import Chat from 'websockets/chat'

// お問い合わせチャットボット
import Chatbot from 'chatbots/chatbot'

// 業種選択
import { CategorySelectTags } from '../users/category_select_tags';

WebpackerReact.setup({
  DataModal,
  Suggests,
  SuggestSelect,
  Kawaii,
  CsvUploader,
  FileUploader,
  GroupRegister,
  LonglistRegister,
  Loading,
  CompanyWebsite,
  DatePicker,
  CompaniesTags,
  MoviesHashtags,
  Reports,
  Needs,
  Clients,
  CompanySearch,
  ClientSearch,
  CompanyUsers,
  Approval,
  EasyNda,
  CompanyDetail,
  ModalPage,
  ModalSinglePage,
  TelReport,
  Twilio,
  Appointment,
  UserQueries,
  UserQueriesList,
  GroupQueriesList,
  Regulations,
  GroupCompanyMailer,
  CheckMail,
  Tags,
  Areas,
  AntiChecks,
  Knowledge,
  KnowledgeTree,
  KnowledgeEditor,
  Rule,
  RuleTree,
  RuleEditor,
  BusinessCard,
  FileTree,
  Movie,
  BarCharts,
  ColorPicker,
  MultipleForm,
  MultipleSelect,
  Matrix,
  Chat,
  Chatbot,
  CategorySelectTags,
  ClosingPackageForm,
})
