import React      from 'react'
import Style   from './style.sass'

import { SITES, STATUSES, ALPHABET } from './properties'

/**
 *  記事エディター
 *  @version 2018/06/10
 */
export default class AntiChecks extends React.Component {

  constructor(props) {

    super(props)

    this.state = { anti_checks: (this.props.anti_checks.length > 0) ? this.props.anti_checks : [{
      company_id: this.props.company.id,
      anti_check_id: null,
      name: '',
      position: '',
      officer_relative: false,
      records: [
        {
          anti_check_id: null,
          provider: 'google',
          prefix: 'a',
          hit: 0,
          comment: '',
        },
        {
          anti_check_id: null,
          provider: 'nikkei',
          prefix: '1',
          hit: 0,
          comment: '',
        }
      ],
      _destroy: false,
    }] }
  }

  /**
   *  チェック対象の取得
   *  @version 2018/06/10
   */
  anti_checks = () => {

    let anti_checks = (this.state.anti_checks || []).slice();
    anti_checks = anti_checks.map( anti_check => {
      return anti_check;
    });

    return this.state.anti_checks;
  }

  // チェック対象の追加
  addAntiChecks = e => {

    let anti_checks = (this.state.anti_checks || []).slice();

    anti_checks.push({
      company_id: this.props.company_id,
      anti_check_id: null,
      name: '',
      position: '',
      officer_relative: false,
      records: [
        {
          anti_check_id: null,
          provider: 'google',
          prefix: ALPHABET[anti_checks.length+1],
          hit: '0',
          comment: '',
        },
        {
          anti_check_id: null,
          provider: 'nikkei',
          prefix: anti_checks.length+1,
          hit: '0',
          comment: '',
        }
      ],
      _destroy: false,
    });

    this.setState({ anti_checks: anti_checks });
  }

  // 削除
  changeDelete = e => {

    let anti_checks = (this.state.anti_checks || []).slice();

    let anti_check = anti_checks[e.target.dataset.index];
    anti_check._destroy = true;
    this.setState({ anti_checks: anti_checks });
  }

  // 総研親族と照合する
  checkOfficerRelatives = e => {

    const check = this.props.officer_relatives.indexOf(e.target.value.replace(/ |　/g, ''));

    let anti_checks = (this.state.anti_checks || []).slice();
    let anti_check = anti_checks[e.target.dataset.index];

    if (check < 0) {
      anti_check.officer_relative = false;
    } else {
      anti_check.officer_relative = true;
    }

    this.setState({ anti_checks: anti_checks });
  }

  /**
   *  表示処理
   *  @version 2018/06/10
   */
  render() {

    return (
      <div>
        { this.state.anti_checks.map((anti_check, index) => {
          const key = `anti_check_${index}`;
          return (
            <div style={{display: anti_check._destroy ? 'none' : 'block'}} {...{key}}>
              <input type='hidden' name={`company_anti_check[anti_checks_attributes][${index}][_destroy]`} value={anti_check._destroy} />
              <div className='u-fs-small u-fw-bold u-mt-15' style={{position: 'relative'}}>
                チェック対象{index+1}
                <div className={Style.AntiChecks__edit} data-index={index} onClick={this.changeDelete}>削除</div>
              </div>
              <div className='js-simplebar c-table u-mt-5'>
                <table className='c-table__content'>
                  <thead>
                    <tr>
                      <th width='40'>連番</th>
                      <th width='240'>チェック対象名</th>
                      <th width='120'>種別(役職など)</th>
                      <th width='90'>ステータス</th>
                      <th width='90'>関連当事者</th>
                      <th width='100'>サイト</th>
                      <th width='40'>No.</th>
                      <th width='100'>HIT件数</th>
                      <th>コメント</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td rowSpan='2' className='u-ta-center'>{index+1}</td>
                      <td rowSpan='2'>
                        <input type='hidden' name={`company_anti_check[anti_checks_attributes][${index}][id]`} value={anti_check.id} />
                        <input type="text" className="c-form-text" placeholder="チェック対象名を入力してください" name={`company_anti_check[anti_checks_attributes][${index}][name]`} defaultValue={anti_check.name} onChange={this.checkOfficerRelatives} autoComplete='off' data-index={index}></input>
                      </td>
                      <td rowSpan='2'>
                        <input type="text" className="c-form-text" placeholder="種別(役職など)を入力してください" name={`company_anti_check[anti_checks_attributes][${index}][position]`} defaultValue={anti_check.position} autoComplete='off'></input>
                      </td>
                      <td rowSpan='2'>
                        <div className='c-form-selectWrap'>
                          <select className='c-form-select' defaultValue={anti_check.status} name={`company_anti_check[anti_checks_attributes][${index}][status]`}>
                            { STATUSES.map((status, index) => {
                              const key = 'status-'+index;
                              return (
                                <option {...{key}} value={status.value}>{status.name}</option>
                              );
                            })}
                          </select>
                        </div>
                      </td>
                      <td rowSpan='2' className='u-ta-center'>
                        <input type='hidden' name={`company_anti_check[anti_checks_attributes][${index}][officer_relative]`} value={anti_check.officer_relative} />
                        {anti_check.officer_relative ? <span className='u-fw-bold u-fc-red'>該当あり</span> : '該当なし'}
                      </td>
                      <td className='u-ta-center u-fc-red u-fw-bold'>
                        {SITES[anti_check.records[0].provider]}
                        { anti_check.records[0].id ?
                          <input type='hidden' name={`company_anti_check[anti_checks_attributes][${index}][anti_check_records_attributes][0][id]`} value={anti_check.records[0].id} /> : null
                        }
                        <input type='hidden' name={`company_anti_check[anti_checks_attributes][${index}][anti_check_records_attributes][0][provider]`} value={anti_check.records[0].provider} />
                      </td>
                      <td>
                        <input type="text" className="c-form-text" placeholder="a" name={`company_anti_check[anti_checks_attributes][${index}][anti_check_records_attributes][0][prefix]`} defaultValue={anti_check.records[0].prefix} autoComplete='off'></input>
                      </td>
                      <td>
                        <input type="text" className="c-form-text u-ta-right" placeholder="HIT件数を入力してください" name={`company_anti_check[anti_checks_attributes][${index}][anti_check_records_attributes][0][hit]`} defaultValue={anti_check.records[0].hit} autoComplete='off'></input>
                      </td>
                      <td>
                        <input type="text" className="c-form-text" placeholder="コメントを入力してください" name={`company_anti_check[anti_checks_attributes][${index}][anti_check_records_attributes][0][comment]`} defaultValue={anti_check.records[0].comment} autoComplete='off'></input>
                      </td>
                    </tr>
                    <tr>
                      <td className='u-ta-center u-fc-blue u-fw-bold'>
                        {SITES[anti_check.records[1].provider]}
                        { anti_check.records[1].id ?
                          <input type='hidden' name={`company_anti_check[anti_checks_attributes][${index}][anti_check_records_attributes][1][id]`} value={anti_check.records[1].id} /> : null
                        }
                        <input type='hidden' name={`company_anti_check[anti_checks_attributes][${index}][anti_check_records_attributes][1][provider]`} value={anti_check.records[1].provider} />
                      </td>
                      <td>
                        <input type="text" className="c-form-text" placeholder="1" name={`company_anti_check[anti_checks_attributes][${index}][anti_check_records_attributes][1][prefix]`} defaultValue={anti_check.records[1].prefix} autoComplete='off'></input>
                      </td>
                      <td>
                        <input type="text" className="c-form-text u-ta-right" placeholder="HIT件数を入力してください" name={`company_anti_check[anti_checks_attributes][${index}][anti_check_records_attributes][1][hit]`} defaultValue={anti_check.records[1].hit} autoComplete='off'></input>
                      </td>
                      <td>
                        <input type="text" className="c-form-text" placeholder="コメントを入力してください" name={`company_anti_check[anti_checks_attributes][${index}][anti_check_records_attributes][1][comment]`} defaultValue={anti_check.records[1].comment} autoComplete='off'></input>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          );
        })}

        <div className='c-btnMain-standard u-mt-15 c-btnSmall' onClick={this.addAntiChecks}>＋ チェック対象を追加する</div>
      </div>
    );
  }
}
