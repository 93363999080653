import React   from 'react'
import Style from '../style.sass'

import Utilities from '../../../scripts/utilities'

import { APPROVAL_FLOW_TYPES } from '../properties'

/**
 *  @version 2018/06/10
 */
export default class ApprovalFlows extends React.Component {

  /**
   *  コンストラクタ
   *  @version 2018/06/10
   */
  constructor(props) {

    super(props);

    this.state = {
      flows: props.flows.length > 0 ? props.flows : [{
        sort: 1,
        division_id: this.props.default_division_id,
        target_user_id: null,
        approval_flow_type: 'division_master',
      }],
    }
  }

  // 承認フローを追加する
  addFlow = () => {

    let flows = (this.state.flows || []).slice();
    flows.push({
      sort: this.state.flows.length + 1,
      division_id: this.props.default_division_id,
      target_user_id: null,
      approval_flow_type: 'division_master',
    });
    this.setState({ flows: flows });
  }

  // 項目の削除
  removeFlow = e => {

    let flows = (this.state.flows || []).slice();

    //flows.splice(e.target.dataset.index, 1);

    flows[e.target.dataset.index]['_destroy'] = 1;

    this.setState({ flows: flows });
  }

  // 変更時
  changeDivisionId = e => {
    
    let flows = (this.state.flows || []).slice();
    flows[e.target.dataset.index].division_id = e.target.value;
    flows[e.target.dataset.index].approval_flow_type = 'division_master';

    this.setState({ flows: flows });
  }

  // 変更時
  changeApprovalFlowType = e => {

    let flows = (this.state.flows || []).slice();

    // 指定部署に所属する人物または事業部長ではない場合は部署は空欄にする
    if (!['division_master', 'division_anyone'].includes(e.target.value)) {
      flows[e.target.dataset.index].division_id = null;
      flows[e.target.dataset.index].target_user_id = null;
    }

    flows[e.target.dataset.index].approval_flow_type = e.target.value;

    this.setState({ flows: flows });
  }

  // 変更時
  changeTargetUserId = e => {

    let flows = (this.state.flows || []).slice();
    flows[e.target.dataset.index].target_user_id = e.target.value;

    this.setState({ flows: flows });
  }

  // 変更時
  changeSort = e => {
    
    let flows = (this.state.flows || []).slice();
    flows[e.target.dataset.index].sort = e.target.value;

    this.setState({ flows: flows });
  }

  // 値の取得
  getFlows = () => {
    return this.state.flows;
  }

  /**
   *  表示処理
   *  @version 2018/06/10
   */
  render() {

    return (
      <div className={`${Style.Approval} c-attention u-mt-30`}>

        <label className='u-fw-bold'>承認フロー</label>
        <p className='u-fc-midBlack u-mt-5 u-fs-small'>承認者欄に指定した部署の役職者に承認権限が与えられます</p>

        { this.state.flows.map( (flow, i) => {

          if (flow._destroy) return null;
          return (

            <div key={'flow-'+i} className={Style.Approval__item}>
              <div>
                <div className='c-form-label u-mt-15'>
                  <label className='u-fs-small'>順序</label>
                </div>
                <div className='c-form-selectWrap'>
                  <select className='c-form-select' value={flow.sort} data-index={i} onChange={this.changeSort}>
                    { Utilities.range(1, 5).map(sort => {
                      const key = 'sort-'+sort;
                      return <option {...{key}} value={sort}>{sort}</option>;
                    })}
                  </select>
                </div>
              </div>
              <div className='u-ml-15'>
                <div className='c-form-label u-mt-15'>
                  <label className='u-fs-small'>部署</label>
                </div>
                <div className='c-form-selectWrap'>
                  <select className='c-form-select' value={flow.division_id || ''} data-index={i} onChange={this.changeDivisionId}>
                    <option value=''>-</option>
                    { this.props.divisions.map(division => {
                      const key = 'division-'+division.id;
                      return <option {...{key}} value={division.id}>{division.name}</option>;
                    })}
                  </select>
                </div>
              </div>
              <div className='u-ml-15'>
                <div className='c-form-label u-mt-15'>
                  <label className='u-fs-small'>役職</label>
                </div>
                <div className='c-form-selectWrap'>
                  <select className='c-form-select' value={flow.approval_flow_type} data-index={i} onChange={this.changeApprovalFlowType}>
                    { APPROVAL_FLOW_TYPES.map((type, index) => {
                      const key = 'type-'+index;
                      return (
                        <option {...{key}} value={type.value}>{type.name}</option>
                      );
                    })}
                  </select>
                </div>
              </div>
              { flow.approval_flow_type == 'target_person' ?
                <div className='u-ml-15'>
                  <div className='c-form-label u-mt-15'>
                    <label className='u-fs-small'>特定の承認者</label>
                  </div>
                  <div className='c-form-selectWrap'>
                    <select className='c-form-select' value={flow.target_user_id} data-index={i} onChange={this.changeTargetUserId}>
                      { this.props.users.map((user, index) => {
                        const key = 'user-'+index;
                        return (
                          <option {...{key}} value={user.id}>{user.name}</option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                : null
              }
              { this.state.flows.length > 1 ?
                <div className='u-ml-15'>
                  <div data-index={i} onClick={e => { this.removeFlow(e) }} className={Style.Approval__close}>
                    削除
                  </div>
                </div>
                :
                null
              }
              
            </div>
          )
        })}

        <div className='c-btnMain-standard u-mt-15 c-btnSmall' onClick={this.addFlow}>＋ 承認フローを追加する</div>
      </div>
    );
  }
}
