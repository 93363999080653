export const SITES = {
  google: 'Google検索',
  nikkei: '日経テレコン',
};

export const STATUSES = [
  { value: 'yet', name: '未' },
  { value: 'approved', name: 'OK' },
  { value: 'rejected', name: 'NG' },
];

export const ALPHABET = '-abcdefghijklmnopqrstuvwxyz';
